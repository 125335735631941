<template>
  <v-dialog
    v-model="dialog"
    :max-width="message.dialogMaxWidth || 500"
    persistent
  >
    <v-card>
      <v-responsive>
        <v-card-title
          v-if="message.title"
        >
          <h3 class="font-weight-regular">
            {{ message.title }}
          </h3>
        </v-card-title>
        <v-card-text>
          <div>
            {{ message.text }}
          </div>
        </v-card-text>
        <v-card-actions class="justify-space-around">
          <v-btn
            v-on:click="accept"
            :color="message.acceptColor"
            class="mr-0"
          >
            {{ message.acceptText }}
          </v-btn>
          <v-btn
            v-on:click="cancel"
            :color="message.cancelColor"
          >
            {{ message.cancelText }}
          </v-btn>
        </v-card-actions>
      </v-responsive>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'mdialog',
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      text: '',
      message: {
        dialogMaxWidth: null,
        acceptText: 'продолжить',
        cancelText: 'отмена',
        acceptColor: 'success',
        cancelColor: '',
        title: '',
        text: '',
      },
    }),
    methods: {
      keyCheck (evnt) {
        if (evnt.code === 'Escape' || evnt.code === 'Backspace') {
          this.cancel()
        } else if (evnt.code === 'Enter') {
          this.accept()
        }
      },
      open (message) {
        window.addEventListener('keyup', this.keyCheck)
        for (const item in message) {
          this.message[item] = message[item]
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
          this.dialog = true
        })
      },
      accept () {
        this.dialog = false
        window.removeEventListener('keyup', this.keyCheck)
        this.resolve()
      },
      cancel () {
        this.dialog = false
        window.removeEventListener('keyup', this.keyCheck)
        this.reject()
      },
    },
  }
</script>

<style scoped>

</style>
